import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import { FaParking } from "react-icons/fa";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Preparació intensiva de les proves físiques per Mossos d'Esquadra, Guàrdia
                    urbana i policies locals.
                </p>
                <p>Vine a entrenar amb nosaltres les proves físiques!</p>
                <p>
                    Els <strong>dissabtes</strong> de <strong>14.00</strong> a{" "}
                    <strong>16.00</strong> hores
                </p>
                <p>
                    La nostra filosofia és extreure el màxim rendiment de cada alumne amb trucs i
                    millores personalitzades.
                </p>

                <p>
                    Es buscarà la millora de{" "}
                    <strong>velocitat, potència, força i resistència</strong>.
                </p>

                <p>
                    D'altra banda, el treball específic per al{" "}
                    <strong>circuit d'agilitat, press banca i course navette</strong>.
                </p>

                <p>
                    <strong>Test setmanal</strong> per a portar un control individualitzat de cada
                    opositor.
                </p>

                <p>
                    El teu nou espai de referència i espais totalment adaptats a totes les
                    necessitats
                </p>

                <p>Carrer de Francesc Vila, 17, 08173 Sant Cugat del Vallès, Barcelona</p>

                <p>
                    Els nostres entrenaments estan dissenyats per portar-vos al vostre estat òptim.
                </p>

                <p>
                    Gran <strong>percentatge d'aprovats</strong> cada any!
                </p>

                <p>
                    <strong>Places limitades!</strong>
                </p>

                <p>
                    <strong>
                        Els packs estan limitats a 2 mesos de durada, és a dir, es poden utilitzar
                        fins a 2 mesos després de la seva compra.
                    </strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Preparació intensiva de les proves físiques per a Mossos d'Esquadra, Guàrdia Urbana i policies locals."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
