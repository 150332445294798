import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Us presentem el millor curs presencial per preparar la 3a prova d'accés a
                        l'oposició lliure de les 1.300 places de Mossos d'Esquadra escala bàsica.
                    </strong>
                </p>

                <p>
                    Enguany amb <strong>NOVETATS</strong> i encara molt més personalitzat.
                </p>

                <p>Dividida en 3 FASES:</p>
                <ul>
                    <li>
                        <strong>FASE ONLINE</strong>
                    </li>
                    <li>
                        <strong>FASE PRESENCIAL</strong>
                    </li>
                    <li>
                        <strong>FASE POST ONLINE</strong>
                    </li>
                </ul>

                <p>
                    <strong>FASE ONLINE</strong>
                </p>
                <p>
                    Una de les novetats d’enguany és l’accés a un nou campus dissenyat pel nostre
                    equip de psicòlegs.
                </p>
                <ul>
                    <li>
                        Accés des del moment de la matriculació, durant 60 dies a un campus online
                        amb vídeo anàlisi de les proves psicotècniques oficials.
                    </li>
                    <li>Explicacions i raonament de l’estructura dels test.</li>
                    <li>Anàlisi de les competències i escales de cada prova.</li>
                    <li>
                        Més de 5 hores de vídeos explicatius dels test oficials, amb especial
                        atenció al test oficial que ha sortit a les convocatòries de Mossos els
                        últims anys.
                    </li>
                </ul>
                <p>Amb aquest campus tindreu tots els conceptes clars i una bona base.</p>

                <p>
                    <strong>FASE PRESENCIAL</strong>
                </p>
                <p>
                    5 hores presencials impartides per un psicòleg clínic amb més de 30 anys
                    d’experiència en selecció i un Mosso d’Esquadra especialista.
                </p>
                <p>
                    <strong>Estructura del curs:</strong>
                </p>
                <ul>
                    <li>
                        2 minuts de presentació de cada alumne en públic on us analitzarem i us
                        donarem una devolució del vostre perfil.
                    </li>
                    <li>Confeccionament del BIODATA i conceptes claus.</li>
                    <li>Estructura del CV i imatge.</li>
                    <li>Anàlisi del perfil policial.</li>
                    <li>Introducció dels test psicoprofessionals amb explicacions.</li>
                </ul>
                <p>Dossier de preparació per cada alumne.</p>

                <p>
                    <strong>FASE POST ONLINE</strong>
                </p>
                <p>
                    Un cop finalitzada la fase online i la fase presencial el nostre psicòleg es
                    connectarà en directe a través de ZOOM per aclarir tots els conceptes clau dels
                    test psicoprofessionals durant 3 hores.
                </p>

                <p>
                    <strong>
                        La formació es realitza al c/Martí Codolar núm. 18, Hospitalet de Llobregat.
                    </strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't per superar la 3a prova d'accés als Mossos d'Esquadra amb el curs més complet i personalitzat!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
