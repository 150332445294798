import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Data:</strong> 2 de febrer de 2025
                    <br />
                    <strong>Lloc:</strong> Crossfit LYNX. C/ Francesc Vila 17, Sant Cugat.
                    <br />
                    <strong>Horari:</strong> 16:00 a 21:00.
                </p>
                <p>
                    Hem creat un nou concepte, una nova manera de posar-te a prova i a més, fer-ho
                    de manera personalitzada i amb seguiment mensual. Una sessió presencial d'una
                    duració de 5 hores aproximadament al nostre centre de formació de Sant Cugat.
                </p>

                <p>
                    És una nova forma de treballar i veure com estàs evolucionant, però a la vegada,
                    sortir de la sessió amb un <strong>resultat numèric orientatiu</strong> d'en
                    quin moment estàs per poder treballar aspectes concrets.
                </p>

                <p>
                    El grup de treball es dividirà en 3 estacions diferents, i cada grup passarà per
                    totes les estacions en una durada aproximada d'1 hora i mitja cada una i tindreu
                    una fitxa de seguiment per anotar els resultats i veure el progrés.
                </p>

                <p>
                    <strong>Estació 1: TEMARI</strong>
                    <br />
                    Simulacre de 30 preguntes de temari en 35 minuts. Aquesta prova es supervisa per
                    un docent especialista en temari de CME. Un cop acabat el simulacre i has tingut
                    la teva puntuació, el docent repassarà una per una cada pregunta, explicant el
                    perquè de cada resposta i analitzant les altres respostes en quines possibles
                    preguntes d'examen poden aparèixer, és a dir, és una manera d'
                    <strong>ESTUDIAR IDEAL</strong>.
                </p>

                <p>
                    <strong>Estació 2: PSICOS</strong>
                    <br />
                    Simulacre de 80 preguntes en 35 minuts. Aquesta prova se supervisa per un docent
                    especialista, psicòleg i agent CME en aquesta matèria i es farà exactament igual
                    que en el temari, es repassaran i es resoldran dubtes de com resoldre exercicis
                    psicotècnics un cop acabat el simulacre.
                </p>

                <p>
                    <strong>Estació 3: FÍSIQUES</strong>
                    <br />
                    Aquesta prova està supervisada per un formador oficial del cos CME, donarà tips
                    i consells per afrontar les proves i seguidament realitzareu un simulacre tant
                    de circuit, press banca i curs navette. Aquesta estació contempla una duració
                    inferior per poder donar temps a fer una dutxa ràpida i canviar d'estació.
                </p>

                <p>
                    En resum, 5 hores de treball <strong>MOLT INTENS</strong> on podeu preparar{" "}
                    <strong>TOTES LES PARTS</strong> de l'oposició en una mateixa tarda i el més
                    important, en un entorn espectacular on volem formar una pinya increïble fins al
                    final d'aquesta oposició. A més, acompanyats sempre de docents d'alt nivell,
                    tots policies i especialistes en les seves matèries.
                </p>

                <p>
                    Les estacions i les sessions seran adaptatives; a mesura que avanci l'oposició,
                    anirem introduint estacions dedicades a la fase de preparació de test de
                    personalitat, biodata, entrevista i altres.
                </p>

                <p>
                    Com a repte i amb un to competitiu sa, els primers llocs obtenen premis,
                    pròximes sessions de tutorització gratuïtes i mòduls de l'app. Anima't i posa't
                    a prova!
                </p>

                <p>
                    <strong>3 ESTACIONS, 1 OBJECTIU, APTE!</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Descobreix la nostra innovadora sessió de preparació personalitzada: 5 hores d'intens treball en grup, amb seguiment expert i resultats tangibles per afrontar amb èxit les oposicions!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
